/*

Sample Model Attribute

 {
                  "@id": "1",
                  "@Code": "TRIM",
                  "@Name": "SI"
                },

*/

// Holds the Model Attributes List from the return ajax data
class hcpv_modelattribute {

    constructor(attributeList) {

        this.attributeList = attributeList;
        this.attributeObj = {};
        this.edmundsAttributeList=[];
        this.codeList=[];

        for (var i = 0; i < attributeList.length; i++) {

            this.attributeObj[attributeList[i]["@id"]] = attributeList[i];
            if(attributeList[i]["@Code"].indexOf("Edmunds")!==-1)this.edmundsAttributeList.push(attributeList[i]);
            var code=attributeList[i]["@Code"];
            if(this.codeList.indexOf(code)==-1){
                this.codeList.push(code);
            }
        }
        this.codeList.sort();
    }

    // Return a Model Attribute with matching id
    getAttributeById(id) {

        for (var i = 0; i < this.attributeList.length; i++) {

            if (this.attributeList[i]["@id"] == id) return this.attributeList[i];
        }

        return null;
    }

    // Return a Model Attribute with matching id  
    getAttributeById2(id) {
        return this.attributeObj[id];
    }


    

}